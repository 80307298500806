import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { SvgEmailActionBold } from '@insights-ltd/design-library/components';
import useAboveMobile from 'components/hooks/useAboveMobile';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

const SentForm = ({ emailAddress }: { emailAddress: string }) => {
  const { t } = useTranslation();
  const aboveMobile = useAboveMobile();
  return (
    <Box
      sx={(theme) => ({
        minHeight: '70vh',
        marginTop: theme.spacing(spacingSizeMap.XL),
        textAlign: 'center',
        paddingRight: theme.spacing(1.75 * spacingSizeMap.L),
        paddingLeft: theme.spacing(1.5 * spacingSizeMap.L),
        '@media screen and (max-width: 768px)': {
          paddingRight: theme.spacing(spacingSizeMap.XL),
          paddingLeft: theme.spacing(spacingSizeMap.XL),
        },
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          marginBottom: theme.spacing(spacingSizeMap.S),
          '> div': {
            width: '4rem',
            height: '4rem',
          },
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '4rem',
            width: '4rem',
            background: theme.palette.pink.light,
            borderRadius: '50%',
            '& > svg': {
              fill: theme.palette.pink.dark,
              height: '2rem',
              width: '2rem',
            },
          })}
        >
          <SvgEmailActionBold title="alert" />
        </Box>
      </Box>
      <Typography
        sx={(theme) => ({ marginBottom: theme.spacing(4) })}
        variant={aboveMobile ? 'h4' : 'h5'}
        gutterBottom
      >
        {t('ui.io.confirm_email.sent_form.heading')}
      </Typography>
      <Typography
        sx={(theme) => ({
          marginBottom: theme.spacing(4),
          lineHeight: '1.75rem',
        })}
        color="textSecondary"
        gutterBottom
      >
        {t('ui.io.confirm_email.sent_form.body', { emailAddress })}
      </Typography>
      <Typography
        sx={() => ({
          lineHeight: '1.75rem',
        })}
        color="textSecondary"
        gutterBottom
      >
        {t('ui.io.confirm_email.sent_form.check_spam')}
      </Typography>
    </Box>
  );
};

export default SentForm;
