import { Box, Container, styled } from '@mui/material';
import LanguageSelector from 'components/LanguageSelector/LanguageSelector';
import {
  SvgInsightsLogo,
  TextV2,
} from '@insights-ltd/design-library/components';
import React from 'react';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

type ExploreHeaderProps = {
  title?: string;
};

const StyledAppHeaderWrapper = styled('header')({
  display: 'flex',
  minHeight: '72px',
  boxShadow: '0 2px 16px -16px rgba(0, 0, 0, 0.5)',
});

const ExploreHeader = ({ title }: ExploreHeaderProps) => {
  return (
    <StyledAppHeaderWrapper>
      <Container
        maxWidth="lg"
        sx={(theme) => ({
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: `${theme.spacing(spacingSizeMap.S)} !important`,
          paddingRight: `${theme.spacing(spacingSizeMap.S)} !important`,
          [theme.breakpoints.up('lg')]: {
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
          },
        })}
        translate="no"
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            '& svg': {
              fill: theme.palette.blue.main,
              width: '130px',
              height: '34px',
            },
          })}
        >
          <SvgInsightsLogo
            data-testid="logo-svg"
            title="Insights logo"
            aria-label="Insights"
          />
        </Box>
        <TextV2 variant="bodyBold">{title}</TextV2>
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', width: '215px' }}
        >
          <LanguageSelector />
        </Box>
      </Container>
    </StyledAppHeaderWrapper>
  );
};

export default ExploreHeader;
