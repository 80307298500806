import React, { PropsWithChildren } from 'react';
import {
  Box,
  Stack,
  styled,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Evaluator as EvaluatorType } from 'types/evaluator';
import Copyright from 'components/Copyright';
import PrivacyPolicyLink from 'components/PrivacyPolicyLink';
import {
  SvgColourCharts,
  TextV2,
} from '@insights-ltd/design-library/components';

type ColourChartSplitLayoutProps = PropsWithChildren<{
  title?: string;
  model?: EvaluatorType;
  keepBanner?: boolean;
}>;

const Content = styled(Stack)(({ theme }) => {
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  return {
    gap: theme.spacing(2),
    flexGrow: 1,
    flexShrink: 0,
    paddingInline: theme.spacing(largeScreen ? 8 : 4),
    paddingBlock: theme.spacing(4),
    width: largeScreen ? '50%' : '100%',
  };
});

const RightContent = styled(Content)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  color: theme.palette.grey[100],
  background: `linear-gradient(270.19deg, ${theme.palette.blue.main} 0%, ${theme.palette.blue.dark} 100%)`,
}));

const ColourChartSplitLayout: React.FC<ColourChartSplitLayoutProps> = ({
  title,
  children,
  model,
  keepBanner = false,
}) => {
  const { t } = useTranslation();
  const largeScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('lg'),
  );

  return (
    <Stack direction={largeScreen ? 'row' : 'column-reverse'} flexGrow={1}>
      <Content>
        {title && (
          <TextV2 variant="h2" component="h1">
            {title}
          </TextV2>
        )}
        <Box flexGrow={1}>{children}</Box>
        <Stack alignItems="center" gap={2}>
          <Copyright />
          <PrivacyPolicyLink />
        </Stack>
      </Content>
      {(keepBanner || largeScreen) && (
        <RightContent>
          <SvgColourCharts aria-hidden="true" width="100%" />
          <Typography variant="h4" component="p">
            {model !== 'DFC'
              ? t('ui.io.messaging.discover_yourself_through_colour')
              : t('ui.io.messaging.discovery_full_circle')}
          </Typography>
          {model !== 'DFC' && (
            <Typography variant="body1">
              {t('ui.io.messaging.find_colour_mix')}
            </Typography>
          )}
        </RightContent>
      )}
    </Stack>
  );
};

export default ColourChartSplitLayout;
