import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';
import { Dialects, Pronoun } from 'types/dialects';
import { useForm } from 'react-hook-form';
import { useGetEvaluatorLanguages } from 'api/evaluator.hooks';
import { Evaluator } from 'types/evaluator';
import { setFocusOnFirstError } from 'utils/formHelpers';
import {
  COUNTRIES,
  INDUSTRY_SECTORS,
  PROFILE_DIALECTS,
  PRONOUN_THEY,
  PRONOUNS_EXCLUSIVE,
} from 'variables';
import { TextV2 } from '@insights-ltd/design-library/components';
import { Select } from './FormInputs';
import FormLayout from './FormLayout';
import FormNavigation from './FormNavigation';
import { IndustrySectors, Step2Data } from './types';
import {
  StyledFieldWrapperEmulator,
  StyledPronounInfoWrapper,
  StyledInputWrapper,
} from './StyledComponents';

type Sectors = (typeof INDUSTRY_SECTORS)[number];

const Step2 = ({
  onNext,
  onBack,
  stepNumber,
  stepMax,
  userInfo,
  model,
}: {
  onNext: (data: Step2Data) => void;
  onBack?: () => void;
  stepNumber: number;
  stepMax: number;
  userInfo: Step2Data;
  model: Evaluator;
}) => {
  const { t, i18n } = useTranslation();

  const [validSectors, setValidSectors] = useState<IndustrySectors[]>([]);

  const languageSelectOption = model === 'EXPLORE' ? 'EXPLORE' : 'ALL_DIALECTS';

  const { data: supportedDialects } = useGetEvaluatorLanguages(
    languageSelectOption,
    i18n.language,
  );

  const {
    handleSubmit,
    setValue,
    control,
    watch,
    setFocus,
    formState: { errors },
  } = useForm<Step2Data>({
    defaultValues: userInfo,
  });

  const currentPronounsSelection = watch('pronoun') as Pronoun | '';
  const currentDialectSelection = watch('preferredDialect') as Dialects;

  const currentSectorSelection = watch('sector') as Sectors | '';

  useEffect(() => {
    const collator = new Intl.Collator(i18n.language);

    setValidSectors(
      INDUSTRY_SECTORS.map((item) => {
        return { sectorKey: item, sectorName: t(item) };
      }).sort((a, b) => {
        if (a.sectorKey === 'ui.io.demographics.job.sector.rather_not_say') {
          return -1;
        }
        if (b.sectorKey === 'ui.io.demographics.job.sector.rather_not_say') {
          return 1;
        }
        return collator.compare(a.sectorName, b.sectorName);
      }),
    );
    // adding t function to dependency array causes tests to hang due to issues with vite, need to revisit.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSectorSelection, setValue, i18n.language]);

  useEffect(() => {
    if (
      PROFILE_DIALECTS[currentDialectSelection].pronouns ===
        PRONOUNS_EXCLUSIVE &&
      currentPronounsSelection === PRONOUN_THEY
    ) {
      setValue('pronoun', '');
    }
  }, [currentDialectSelection, currentPronounsSelection, setValue]);

  const activePronouns = PROFILE_DIALECTS[currentDialectSelection].pronouns;

  return (
    <FormLayout
      step={stepNumber}
      stepMax={stepMax}
      subtitle={t('ui.io.about_you.step_two.subtitle')}
      model={model}
    >
      <form
        onSubmit={handleSubmit(onNext, (submitErrors) => {
          setFocusOnFirstError(submitErrors, setFocus);
        })}
        noValidate
      >
        <StyledInputWrapper>
          <StyledFieldWrapperEmulator>
            {model === 'EXPLORE' && supportedDialects ? (
              <Select
                id="preferredDialect"
                name="preferredDialect"
                label={t('ui.io.demographics.languages.input_description')}
                control={control}
                autoComplete="language"
                error={Boolean(errors.preferredDialect)}
                errorMessage={t('ui.io.about_you.error.value_required')}
              >
                {supportedDialects.dialects.map((supportedDialect) => (
                  <MenuItem
                    key={supportedDialect.value}
                    value={supportedDialect.value}
                    disableRipple
                  >
                    {supportedDialect.textKey}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Select
                id="preferredDialect"
                name="preferredDialect"
                label={t('ui.io.demographics.languages.input_description')}
                control={control}
                error={Boolean(errors.preferredDialect)}
                errorMessage={t('ui.io.about_you.error.value_required')}
                autoComplete="language"
              >
                {Object.entries(PROFILE_DIALECTS)
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  .filter(([key, value]) => value.enabled)
                  .sort((a, b) =>
                    t(a[1].textKey).localeCompare(t(b[1].textKey)),
                  )
                  .map(([value, { textKey }]) => (
                    <MenuItem disableRipple key={value} value={value}>
                      {t(textKey)}
                    </MenuItem>
                  ))}
              </Select>
            )}
          </StyledFieldWrapperEmulator>
        </StyledInputWrapper>
        <StyledInputWrapper>
          <Select
            id="pronoun"
            name="pronoun"
            label={t('ui.io.pronouns.input_description')}
            control={control}
            error={Boolean(errors.pronoun)}
            errorMessage={t('ui.io.about_you.error.pronoun_required')}
            autoComplete="sex"
          >
            <MenuItem disabled hidden value="" style={{ display: 'none' }}>
              {t('ui.io.about_you.please_select.placeholder')}
            </MenuItem>
            {activePronouns.map(({ textKey, value }) => (
              <MenuItem disableRipple key={value} value={value}>
                {t(textKey)}
              </MenuItem>
            ))}
          </Select>
        </StyledInputWrapper>
        {model !== 'DFC' && (
          <>
            <Select
              id="countryOfResidence"
              name="countryOfResidence"
              label={t('ui.io.demographics.country.input_description')}
              control={control}
              error={false}
              errorMessage=""
              autoComplete="country"
              required={false}
            >
              <MenuItem disabled hidden value="" style={{ display: 'none' }}>
                {t('ui.io.about_you.please_select.placeholder')}
              </MenuItem>
              {COUNTRIES.map((textKey) => (
                <MenuItem disableRipple key={textKey} value={textKey}>
                  {t(textKey)}
                </MenuItem>
              ))}
            </Select>
            <Select
              id="sector"
              name="sector"
              label={t('ui.io.demographics.job.sector.input_description')}
              control={control}
              error={false}
              errorMessage=""
              data-testid="sectors-select"
              autoComplete="job-sector"
              required={false}
            >
              <MenuItem disabled hidden value="" style={{ display: 'none' }}>
                {t('ui.io.about_you.please_select.placeholder')}
              </MenuItem>
              {validSectors.map((item) => (
                <MenuItem
                  disableRipple
                  key={item.sectorKey}
                  value={item.sectorKey}
                >
                  {item.sectorName}
                </MenuItem>
              ))}
              <MenuItem
                disableRipple
                key="ui.io.demographics.job.sector.other.name"
                value="ui.io.demographics.job.sector.other.name"
              >
                {t('ui.io.demographics.job.sector.other.name')}
              </MenuItem>
            </Select>
          </>
        )}
        <StyledPronounInfoWrapper>
          <TextV2 component="p" color="textPrimary" variant="bodyBold">
            {t('ui.io.onboarding.pronoun_info.title')}
          </TextV2>
          <TextV2 component="p" color="textSecondary">
            {t('ui.io.onboarding.pronoun_info.body')}
          </TextV2>
        </StyledPronounInfoWrapper>
        <StyledPronounInfoWrapper>
          <TextV2 component="p" color="textPrimary" variant="bodyBold">
            {t('ui.io.onboarding.demographic_info.title')}
          </TextV2>
          <TextV2 component="p" color="textSecondary">
            {t('ui.io.onboarding.demographic_info.body')}
          </TextV2>
        </StyledPronounInfoWrapper>
        <FormNavigation onBack={onBack} />
      </form>
    </FormLayout>
  );
};

export default Step2;
