import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import {
  SvgAlertDiamond,
  TextV2,
} from '@insights-ltd/design-library/components';

const FullScreenError = ({
  heading,
  message,
}: {
  heading?: string;
  message: string;
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '100%',
        minHeight: '100%',
      }}
    >
      <Box
        mb={6}
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '7.5rem',
          width: '7.5rem',
          background: theme.palette.pink.light,
          borderRadius: '50%',
          '& > svg': {
            fill: theme.palette.pink.dark,
            height: '3.75rem',
            width: '3.75rem',
          },
        })}
      >
        <SvgAlertDiamond title="alert" />
      </Box>
      <TextV2 variant="h1" component="h1">
        {heading || t('ui.io.error')}
      </TextV2>
      <Box mt={3} maxWidth={560} textAlign="center">
        <TextV2 component="p">{message}</TextV2>
      </Box>
    </Box>
  );
};

export default FullScreenError;
